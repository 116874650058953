import { http } from '@/http/axios.js'

// 列表
export function getListAPI(data) {
  return http({
    url: `/manage/user/dynamic/page`,
    method: 'post',
    data
  })
}
// 评论列表
export function getCommentListAPI(userDynamicId) {
  return http({
    url: `/manage/user/dynamic/comment/page`,
    method: 'get',
    params: { userDynamicId }
  })
}
// del
export function delAPI(id) {
  return http({
    url: `/manage/user/dynamic/delete?id=${id}`,
    method: 'post',
    data: {}
  })
}
// del评论
export function delCommentAPI(id) {
  return http({
    url: `/manage/user/dynamic/comment/delete?id=${id}`,
    method: 'post',
    data: {}
  })
}
//审核
export function changeStatusAPI(data) {
  return http({
    url: `/manage/user/dynamic/updateStatus`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/manage/user/dynamic/detail`,
    method: 'get',
    params: { id }
  })
}
export function getJubaoListAPI(params) {
  return http({
    url: `/manage/user/dynamic/pageReport`,
    method: 'get',
    params
  })
}
// 统计
export function getStatAPI(params) {
  return http({
    url: `/manage/user/dynamic/stat`,
    method: 'get',
    params
  })
}
