<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form class="search-form" ref="form" inline :model="searchData" label-width="">
      <el-form-item label="用户ID">
        <el-input style="width: 200px" v-model="searchData.uid" size="mini" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input style="width: 200px" v-model="searchData.city" size="mini" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="可见度">
        <el-select style="width: 300px" clearable size="mini" v-model="searchData.privateDynamicArr" placeholder="请选择" multiple>
          <el-option label="全部可见" :value="3"> </el-option>
          <el-option label="仅好友可见" :value="1"> </el-option>
          <el-option label="仅自己可见" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-divider></el-divider> -->
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge :value="stat.total">
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge :value="stat.waitCount">
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge :value="stat.rejectCount">
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge :value="stat.passCount">
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
    </div>

    <!-- list -->
    <el-table :data="list">
      <!-- 展开 -->
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form label-width="100px">
            <el-form-item label="用户信息：">
              <div class="user">
                <el-image style="width: 60px; height: 60px; border-radius: 5px" :preview-src-list="[row.user.face]" :src="row.user.face" fit="cover">
                  <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
                </el-image>
                <div class="info">
                  <div class="nickname">
                    <span> {{ row.user.nickname }}</span>
                    <span>
                      <el-tag v-if="row.user.sex == 1" type="primary" size="mini">男</el-tag>
                      <el-tag v-if="row.user.sex == 2" type="primary" size="mini">女</el-tag>
                      <el-tag v-if="row.user.sex == 0" type="primary" size="mini">未知</el-tag>
                    </span>
                  </div>
                  <div class="lv">
                    <el-tag type="primary" size="mini">{{ row.user.levelDsc }}</el-tag>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="uid" label="用户ID" min-width="80"></el-table-column>
      <el-table-column prop="content" label="内容" min-width="130"></el-table-column>
      <el-table-column prop="photos" label="照片" min-width="100">
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 5px" :preview-src-list="row.photos" :src="row.photos[0]" fit="cover">
            <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="mini">待审核</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="mini">不通过</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="mini">通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isShowCity" label="是否显示城市" min-width="100">
        <template slot-scope="{ row }">
          <el-tag :type="row.isShowCity ? 'primary' : 'danger'" size="mini">{{ row.isShowCity ? '是' : '否' }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isShowCity" label="可见度" min-width="100">
        <template slot-scope="{ row }">
          <!--          <el-tag :type="row.privateDynamic ? 'primary' : 'danger'" size="mini">{{ row.privateDynamic ? '是' : '否' }}</el-tag>-->
          <el-tag v-if="row.privateDynamic === 1" size="mini" type="warning">{{ row.privateDynamicDsc }}</el-tag>
          <el-tag v-if="row.privateDynamic === 2" size="mini" type="danger">{{ row.privateDynamicDsc }}</el-tag>
          <el-tag v-if="row.privateDynamic === 3" size="mini" type="success">{{ row.privateDynamicDsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="city" label="城市" min-width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pv" label="浏览量" min-width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="collectionCount" label="收藏量" min-width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="commentCount" label="评论数" min-width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="humanTime" label="发布时间" min-width="100" show-overflow-tooltip></el-table-column>

      <el-table-column prop="id" label="操作" width="200" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="showJubao(row)"> 查看举报 </el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="showDetail(row)"> 详情 </el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleReview(row)"> 审核 </el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 详情弹窗 -->
    <el-drawer :visible.sync="show_detail" direction="rtl" size="50%">
      <Detail ref="detail" :detail="detail"></Detail>
    </el-drawer>
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="show_review" width="700px">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="状态：">
          <el-radio-group v-model="formData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="10">不通过</el-radio>
            <el-radio :label="20">通过</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_review = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitReview" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 举报 -->
    <JubaoList ref="jubao" :userDynamicId="dynamic_id"></JubaoList>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI, delAPI, changeStatusAPI, getStatAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
import JubaoList from './jubao-list.vue'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail, JubaoList },
  data() {
    return {
      show_detail: false,
      show_review: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        status: -1,
        uid: '',
        city: '',
        privateDynamicArr: []
      },
      total: 0,
      detail: {},
      formData: {
        id: '',
        status: 0
      },
      dynamic_id: '',
      stat: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    async getStat() {
      this.stat = await getStatAPI()
    },
    async getList() {
      let { page, pagesize, status, city, uid, privateDynamicArr } = this.searchData
      let params = { page, pagesize, city, uid, privateDynamicArr }
      if (status != -1) {
        params.status = status
      }
      const res = await getListAPI(params)
      this.list = res.records || []
      this.total = res.totalRow
      this.getStat()
    },
    // 点击状态
    handleClickTab(i) {
      if (this.searchData.status === i) return
      this.searchData.status = i
      this.getList()
    },
    // 详情按钮
    async showDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
      this.$nextTick(() => {
        this.$refs.detail.getList()
      })
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 审核
    handleReview({ id }) {
      this.formData = {
        id,
        status: 0
      }
      this.show_review = true
    },
    // 提交审核
    submitReview() {
      changeStatusAPI(this.formData).then(() => {
        this.getList()
        this.$message.success('操作成功')
        this.show_review = false
        this.$bus.$emit('updateTip')
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    showJubao({ id }) {
      this.dynamic_id = id
      this.$refs.jubao.show_jubao = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-divider {
    margin-top: 0;
  }

  .search-form {
    margin-bottom: 20px;
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }

  // 用户信息
  .user {
    display: flex;
    align-items: center;

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;

      .nickname {
        display: flex;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
