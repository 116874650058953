<template>
  <div class="wrapper">
    <!-- 用户信息 -->
    <div class="user-box">
      <el-image style="width: 60px; height: 60px; border-radius: 5px" :preview-src-list="[detail.user.face]" :src="detail.user.face" fit="cover">
        <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
      </el-image>
      <div class="info">
        <div class="nickname">
          <span> {{ detail.user.nickname }}</span>
          <span>
            <el-tag v-if="detail.user.sex == 1" type="primary" size="mini">男</el-tag>
            <el-tag v-if="detail.user.sex == 2" type="primary" size="mini">女</el-tag>
            <el-tag v-if="detail.user.sex == 0" type="primary" size="mini">未知</el-tag>
          </span>
        </div>
        <div class="lv">
          <el-tag type="primary" size="mini">{{ detail.user.levelDsc }}</el-tag>
        </div>
      </div>
    </div>

    <div class="moment-list">
      <admin-title title="评论列表"></admin-title>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="user.nickname" label="用户名" min-width="100px"> </el-table-column>
        <el-table-column prop="content" label="内容" min-width="120px"> </el-table-column>
        <el-table-column prop="humanTime" label="发布时间" min-width="100"> </el-table-column>
        <el-table-column prop="id" label="操作" width="120" fixed="right">
          <template slot-scope="{ row }">
            <el-link type="danger" :underline="false" @click="handleDel(row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { getCommentListAPI, delCommentAPI } from './api.js'
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      total: 0,
      searchData: {
        page: 1,
        pageSize: 10
      }
    }
  },

  mounted() {},

  methods: {
    async getList() {
      const res = await getCommentListAPI(this.detail.id)
      this.list = res.records
      this.total = res.totalRow
    },
    handleDel(id) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delCommentAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  // 用户信息
  .user-box {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      .nickname {
        display: flex;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  .moment-list {
    padding: 0 20px;
  }
}
</style>
