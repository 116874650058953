<template>
  <el-drawer title="举报列表" size="55%" :visible.sync="show_jubao" direction="rtl" @opened="open">
    <div style="padding: 0 20px">
      <el-table :data="list" style="width: 100%">
        <el-table-column type="index" label="序号" :index="indexMethod" width="80"> </el-table-column>
        <el-table-column prop="content" label="举报内容" align="left" min-width="120"> </el-table-column>
        <el-table-column prop="createTime" label="举报时间" align="left" min-width="120"> </el-table-column>
        <el-table-column prop="createTime" label="举报人" align="left" min-width="120">
          <template slot-scope="{ row }">
            <div v-if="row.reportUser">
              <div class="row">
                id：
                <el-link type="primary" :underline="false" @click="$router.push({ name: 'CustomerList', query: { id: row.reportUser.id } })">{{
                  row.reportUser.id
                }}</el-link>
              </div>
              <div class="row">昵称：{{ row.reportUser.nickname }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
  </el-drawer>
</template>

<script>
import { getJubaoListAPI } from './api'
export default {
  name: 'JubaoList',
  props: {
    userDynamicId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      show_jubao: false,
      list: [],
      total: 0,
      searchData: {
        page: 1,
        pagesize: 10
      }
    }
  },

  mounted() {},

  methods: {
    open() {
      this.getList()
    },
    async getList() {
      const params = {
        ...this.searchData,
        userDynamicId: this.userDynamicId
      }
      const res = await getJubaoListAPI(params)
      this.list = res.records
      this.total = res.totalRow
    },
    // 序号自增--方法
    indexMethod(index) {
      // 拿到页码
      const currentPage = this.searchData.page
      // 拿到每页记录条数
      const pageSize = this.searchData.pagesize
      // 根据值进行计算返回
      return index + 1 + (currentPage - 1) * pageSize
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
